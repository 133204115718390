<template>
    <div class="section-news" data-scroll="4" id="news" v-if="posts.length > 0">
        <div class="container">
            <h2 class="section-news__title"><img :class="`lazyload ${$i18n.locale}`" :data-src="webp(require(`../../../public/img/corporat/title/news-${$i18n.locale}.png`))" alt="News"></h2>
            <div class="section-news__content">
                <div class="section-news__slide">
                    <NewsSlide :posts="posts"/>
                </div>

                <div class="section-news__btn"><a class="btn" href="/news/">{{ $t('seeAll') }}</a></div>
            </div><!--/ .section-news__content -->
        </div><!--/ container -->
    </div>
</template>

<script>
    const NewsSlide = () => import("./NewsSlide");

    export default {
        name: "News",

        components : {
            NewsSlide,
        },
        props: {
            posts: Array
        },
    }
</script>

<style lang="scss">
    @import '../../assets/scss/mixin/_mixin';

    $sm: 768;
    $md: 992;
    $lg: 1200;

    .section-news {
        padding-top: 30px;
        @include media(set-min-w, $sm) {
            padding-top: 60px;
        }

        .container {
            max-width: 1360px;
            padding: 0 15px;
            margin: 0 auto;
        }

        &__title {
            margin-bottom: 40px;
            text-align: center;

            img {
                max-height: 30px;
                @include media(set-min-w, $md) {
                    max-height: none;
                }
            }
        }

        &__slide {
            max-width: 320px;
            margin: 0 auto;
            @include media(set-min-w, $sm) {
                max-width: none;
                padding-left: 50px;
            }
            @include media(set-min-w, $lg) {
                padding-left: 70px;
            }

            .swiper-slide {
                @include media(set-min-w, $sm) {
                    width: 50%;
                }
                @include media(set-min-w, $md) {
                    width: 33.333333%;
                }
            }

            .col {
                margin-bottom: 35px;
                @include media(set-min-w, $sm) {
                    padding-right: 12px;
                    padding-left: 12px;
                    margin-bottom: 45px;
                }
            }

            .disable {
                opacity: .5;
            }
        }

        &__content {
            position: relative;
            line-height: 24px;
            color: #6a6a6a;
            font-size: 16px;

            a {
                color: inherit;
            }

            .title {
                margin-top: 25px;
                margin-bottom: 15px;
                color: #000;
                font-size: 22px;
                font-weight: 700;
                @include media(set-min-w, $sm) {
                    height: 50px;
                    overflow: hidden;
                }
            }

            .image {
                text-align: center;

                img {
                    width: 100%;
                }
            }

            p {
                margin-bottom: 25px;
                @include media(set-min-w, $sm) {
                    height: 100px;
                    overflow: hidden;
                }
            }

            .date {
                color: #000;
                font-size: 14px;
                font-style: italic;
            }

            .bold {
                font-weight: 700;
                font-style: normal;
            }

            .navigation {
                position: absolute;
                top: 67px;
                left: 0;
                display: none;
                z-index: 9;

                @include media(set-min-w, $sm) {
                    display: block;
                }

                a {
                    display: block;
                    width: 42px;
                    height: 42px;
                    margin: 10px auto;
                    background-repeat: no-repeat;
                    background-position: center center;
                    border: 1px solid #cfcfcf;
                    border-radius: 50%;

                    &:active,
                    &:focus {
                        outline: none;
                    }

                    &:hover {
                        border-color: #000;
                    }

                    @include media(set-min-w, $lg) {
                        width: 62px;
                        height: 62px;
                    }
                }

                .nav-previous {
                    background-image: url("../../../public/img/icon/icon-arrow-half.png");
                }

                .nav-next {
                    transform: rotateY(-180deg);
                    background-image: url("../../../public/img/icon/icon-arrow-half.png");
                }
            }
        }

        &__btn {
            padding: 10px 0;
            text-align: center;

            .btn {
                display: inline-block;
                min-width: 198px;
                padding: 10px;
                background-color: #ffaf04;
                border-radius: 30px;
                color: #fff;
                font-size: 16px;
                font-weight: 700;
                text-align: center;
                text-transform: uppercase;
            }
        }
    }

    .webp {
        .section-news {
            &__content {
                .navigation {
                    .nav-previous {
                        background-image: url("../../../public/img/icon/icon-arrow-half.webp");
                    }
                    .nav-next {
                        background-image: url("../../../public/img/icon/icon-arrow-half.webp");
                    }
                }
            }
        }
    }

</style>